














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import 'vue-select/dist/vue-select.css';
import progressBar from '@/components/form/progress-bar.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import FormSwitch from '@/components/form/form-switch.vue';
import errorMessage from '@/components/form/error-message.vue';
import { CASE_STATUS, WEBSITE_URL } from '@/constant/constants';
import { api } from '@/service/instance';

const counrtyItem = {
  country: '',
  isSelected: false,
};
const individualAgreementItem = {
  designation: '',
  description: '',
  isSelected: false,
};
const vehicleList = {
  Kennzeichen: '',
  Fahrzeugart: '',
  Gesamtgewicht: '',
  isSelected: false,
};

const vehicleListType = 2;
const individualAgreemenType = 3;
export default Vue.extend({
  name: 'forwarding-activities',
  components: {
    progressBar,
    popUpModal,
    FormSwitch,
    errorMessage,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'progressBar', 'id', 'formData', 'isAPIObserver', 'AMSAPISendStatus'],
  data() {
    return {
      IndividualAgreements: false,
      TimoCom: 0,
      LoadFox: 0,
      Trans: 0,
      Cargoclix: 0,
      BulkExchange: 0,
      Sonstige: 0,
      SonstigeText: '',
      Others: false,
      Others2: false,
      OthersText: '',
      OthersText2: '',
      AGB_449: false,
      ADSp: false,
      AGB_BSK: false,
      AGBremovals: false,
      VBGL: false,
      key: 'LOGISTICS_INSURANCE',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      AGB: false,
      AGBError: false,
      grossAnnual: 0,
      grossAnnualError: false,
      GeneralFreight: 0,
      ChilledGoods: 0,
      FrozenFood: false,
      TemperatureControlled: 0,
      over100k: false,
      HighQuality: 0,
      over300k: false,
      CEPServices: 0,
      Bulk: 0,
      MovingGoods: 0,
      HeavyLift: 0,
      GoodsInTank: 0,
      MotorVehiclesOfAllKinds: 0,
      artMoneyValuablesDocuments: 0,
      animalsAndPlants: 0,
      SumSpecialTypes: 0,
      SumSpecialTypesError: false,
      sumSpatialAreaValuesError: false,
      OrganizationError: false,
      MotorVehicle: false,
      Lohnfuhr: false,
      See: false,
      Luft: false,
      KnownSender: false,
      RegulatedAgent: false,
      Bahn: false,
      Binnenschiff: false,
      Multimodal: false,
      UnusualLogisticalServices: false,
      UnusualLogisticalServicesError: false,
      priceLabeling: false,
      assembly: false,
      repairs: false,
      textiles: false,
      OrderProcessing: false,
      goodsToBeDelivered: false,
      otherProcessingOfCustomsGoods: false,
      customaryForForwardingAgents: false,
      SonstigeUnusualLogisticalServices: '',
      feedBack: false,
      Dokumente: false,
      Weltweit: false,
      Deutschland: false,
      Angrenzende: false,
      Europa: false,
      Nordamerika: false,
      Mittelamerika: false,
      Sudamerika: false,
      Afrika: false,
      China: false,
      Japan: false,
      Asien: false,
      Arabische: false,
      Australien: false,
      spatialAreaValues: {
        regionalWithin150km: 0,
        germany: 0,
        neighboringCountries: 0,
        europe: 0,
        worldwide: 0,
      },
      spatialAreaLabels: [
        {
          name: 'regionalWithin150km',
          text: 'Regional bis 150 km um den Unternehmenssitz (auch grenzüberschreitend)',
        },
        {
          name: 'germany',
          text: 'Deutschland',
        },
        {
          name: 'neighboringCountries',
          text: 'Angrenzende Länder (A, B, CH, CZE, DK, F, L, NL, PL)',
        },
        {
          name: 'europe',
          text: 'Europa (geografische Grenzen), den Mittelmeeranrainerstaaten und Zyperns',
        },
        {
          name: 'worldwide',
          text: 'Weltweit',
        },
      ],
      insuranceCoverage: false,
      insuranceCoverageError: false,
      T1Papers: '',
      T2Papers: '',
      freeCirculation: '',
      MarketRegulationGoods: false,
      subjectToExciseDuty: false,
      CustomsClearanceOutsideEU: false,
      CustomsClearanceOutsideEUText: '',
      FiscalRepresentation: false,
      SubmissionDeclarations: false,
      commitmentAccording: false,
      ForwadingActivitesRemarks: '',
      documentsIssuedWithAnObligation: false,
      documentsIssuedWithAnObligationError: false,
      TBL: false,
      FBL: false,
      FCT: false,
      FCR: false,
      AWB: false,
      // foreignUnitsPromoted: false,
      fileUploaded: false,
      insuranceFile: {
        name: '',
        base64: '',
        extension: '',
      },
      vehicleListTypes: [
        {
          ...vehicleList,
        },
      ],
      individualAgreemenItems: [
        {
          ...individualAgreementItem,
        },
      ],
      mainSubjectError: false,
      mainPriceError: false,
      byvehicleListType: vehicleListType,
      byindividualAgreemenType: individualAgreemenType,
      Gesamtgewicht: [
        'bitte Auswahl treffen',
        '<= 3,5 to.',
        '<= 7,5 to.',
        '<= 12 to.',
        '> 12 to.',
      ],
      AGBinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      HaftunginsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      UnusualLogisticalServicesFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      TBLinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      AWBinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      DokumenteFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      OthersFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      validation: true,
    };
  },
  watch: {
    grossAnnual: 'calculateSumSpecialTypes',
    regionalUpTo150Percent: 'calculateSumSpecialTypes',
    DeutschlandPercentage: 'calculateSumSpecialTypes',
    BorderingCountriesPercentage: 'calculateSumSpecialTypes',
    EuropePercentage: 'calculateSumSpecialTypes',
    GeneralFreight: 'calculateSumSpecialTypes',
    ChilledGoods: 'calculateSumSpecialTypes',
    TemperatureControlled: 'calculateSumSpecialTypes',
    HighQuality: 'calculateSumSpecialTypes',
    CEPServices: 'calculateSumSpecialTypes',
    Bulk: 'calculateSumSpecialTypes',
    MovingGoods: 'calculateSumSpecialTypes',
    HeavyLift: 'calculateSumSpecialTypes',
    GoodsInTank: 'calculateSumSpecialTypes',
    MotorVehiclesOfAllKinds: 'calculateSumSpecialTypes',
    artMoneyValuablesDocuments: 'calculateSumSpecialTypes',
    animalsAndPlants: 'calculateSumSpecialTypes',
    Sonstige: 'calculateSumSpecialTypes',
  },
  methods: {
    onFileChange(event: any) {
      const labelFileMame = event.target.attributes.name.value;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        switch (labelFileMame) {
          case 'AGBinsuranceFile':
            this.AGBinsuranceFile.fileUploaded = false;
            this.AGBinsuranceFile.uploadPercentage = 0;
            this.AGBinsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'HaftunginsuranceFile':
            this.HaftunginsuranceFile.fileUploaded = false;
            this.HaftunginsuranceFile.uploadPercentage = 0;
            this.HaftunginsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'UnusualLogisticalServicesFile':
            this.UnusualLogisticalServicesFile.fileUploaded = false;
            this.UnusualLogisticalServicesFile.uploadPercentage = 0;
            this.UnusualLogisticalServicesFile.originalName = event.target.files[0].name;
            break;
          case 'TBLinsuranceFile':
            this.TBLinsuranceFile.fileUploaded = false;
            this.TBLinsuranceFile.uploadPercentage = 0;
            this.TBLinsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'AWBinsuranceFile':
            this.AWBinsuranceFile.fileUploaded = false;
            this.AWBinsuranceFile.uploadPercentage = 0;
            this.AWBinsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'DokumenteFile':
            this.DokumenteFile.fileUploaded = false;
            this.DokumenteFile.uploadPercentage = 0;
            this.DokumenteFile.originalName = event.target.files[0].name;
            break;
          case 'OthersFile':
            this.OthersFile.fileUploaded = false;
            this.OthersFile.uploadPercentage = 0;
            this.OthersFile.originalName = event.target.files[0].name;
            break;
          default:
            console.log('default');
            break;
        }
        this.insuranceFile.name = event.target.files[0].name;
        this.insuranceFile.base64 = reader.result!.toString();
        this.insuranceFile.extension = event.target.files[0].name.substr(
          event.target.files[0].name.lastIndexOf('.') + 1,
        );
      });
      reader.readAsDataURL(event.target.files[0]);
    },
    uploadFileToServer(name: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = {
        id: this.id,
        path: 'forwarding-activities',
        // eslint-disable-next-line @typescript-eslint/camelcase
        investment_file: {
          ...this.insuranceFile,
        },
      };
      api.post('upload_logistics_file', data, {
        baseURL: WEBSITE_URL,
        onUploadProgress(progressEvent: any) {
          that.fileUploadPercent(name, (progressEvent.loaded / progressEvent.total) * 100);
          // that.uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        },
      }).then((result) => {
        // console.log(result.data.data);
        // this.fileUploaded = true;
        this.fileUploadName(result.data.data, name, true);
      }).catch((error) => {
        this.fileUploadName('', name, false);
      });
    },
    fileUploadName(name: string, modelName: string, status: boolean) {
      switch (modelName) {
        case 'AGBinsuranceFile':
          this.AGBinsuranceFile.name = name;
          this.AGBinsuranceFile.fileUploaded = status;
          break;
        case 'HaftunginsuranceFile':
          this.HaftunginsuranceFile.name = name;
          this.HaftunginsuranceFile.fileUploaded = status;
          break;
        case 'UnusualLogisticalServicesFile':
          this.UnusualLogisticalServicesFile.name = name;
          this.UnusualLogisticalServicesFile.fileUploaded = status;
          break;
        case 'TBLinsuranceFile':
          this.TBLinsuranceFile.name = name;
          this.TBLinsuranceFile.fileUploaded = status;
          break;
        case 'AWBinsuranceFile':
          this.AWBinsuranceFile.name = name;
          this.AWBinsuranceFile.fileUploaded = status;
          break;
        case 'DokumenteFile':
          this.DokumenteFile.name = name;
          this.DokumenteFile.fileUploaded = status;
          break;
        case 'OthersFile':
          this.OthersFile.name = name;
          this.OthersFile.fileUploaded = status;
          break;
        default:
          console.log('default');
          break;
      }
    },
    fileUploadPercent(name: string, value: number) {
      switch (name) {
        case 'AGBinsuranceFile':
          this.AGBinsuranceFile.uploadPercentage = value;
          break;
        case 'HaftunginsuranceFile':
          this.HaftunginsuranceFile.uploadPercentage = value;
          break;
        case 'UnusualLogisticalServicesFile':
          this.UnusualLogisticalServicesFile.uploadPercentage = value;
          break;
        case 'TBLinsuranceFile':
          this.TBLinsuranceFile.uploadPercentage = value;
          break;
        case 'AWBinsuranceFile':
          this.AWBinsuranceFile.uploadPercentage = value;
          break;
        case 'DokumenteFile':
          this.DokumenteFile.uploadPercentage = value;
          break;
        case 'OthersFile':
          this.OthersFile.uploadPercentage = value;
          break;
        default:
          console.log('default');
          break;
      }
    },
    valueMore100(val: any) {
      if (val === '') {
        return 0;
      }
      if (val > 100) {
        return 100;
      }
      return val;
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    calculateSumSpecialTypes() {
      this.GeneralFreight = this.valueMore100(this.GeneralFreight);
      this.ChilledGoods = this.valueMore100(this.ChilledGoods);
      this.TemperatureControlled = this.valueMore100(this.TemperatureControlled);
      this.HighQuality = this.valueMore100(this.HighQuality);
      this.CEPServices = this.valueMore100(this.CEPServices);
      this.Bulk = this.valueMore100(this.Bulk);
      this.MovingGoods = this.valueMore100(this.MovingGoods);
      this.HeavyLift = this.valueMore100(this.HeavyLift);
      this.GoodsInTank = this.valueMore100(this.GoodsInTank);
      this.MotorVehiclesOfAllKinds = this.valueMore100(this.MotorVehiclesOfAllKinds);
      this.artMoneyValuablesDocuments = this.valueMore100(this.artMoneyValuablesDocuments);
      this.animalsAndPlants = this.valueMore100(this.animalsAndPlants);
      this.Sonstige = this.valueMore100(this.Sonstige);

      this.SumSpecialTypes = this.GeneralFreight + this.ChilledGoods + this.TemperatureControlled + this.HighQuality + this.CEPServices + this.Bulk + this.MovingGoods + this.HeavyLift + this.GoodsInTank + this.MotorVehiclesOfAllKinds + this.artMoneyValuablesDocuments + this.animalsAndPlants + this.Sonstige;
    },

    changeWeltweit() {
      this.Deutschland = this.Weltweit;
      this.Angrenzende = this.Weltweit;
      this.Europa = this.Weltweit;
      this.Nordamerika = this.Weltweit;
      this.Mittelamerika = this.Weltweit;
      this.Sudamerika = this.Weltweit;
      this.Afrika = this.Weltweit;
      this.China = this.Weltweit;
      this.Japan = this.Weltweit;
      this.Asien = this.Weltweit;
      this.Arabische = this.Weltweit;
      this.Australien = this.Weltweit;
    },
    dublicateRow(fillData: boolean, type: number) {
      let item;
      switch (type) {
        case this.byvehicleListType:
          item = JSON.parse(JSON.stringify(vehicleList));
          if (fillData && this.vehicleListTypes.length > 0) {
            item = JSON.parse(JSON.stringify(this.vehicleListTypes[this.vehicleListTypes.length - 1]));
          }
          this.vehicleListTypes.push({
            ...item,
          });
          break;
        case this.byindividualAgreemenType:
          item = JSON.parse(JSON.stringify(individualAgreementItem));
          if (fillData && this.individualAgreemenItems.length > 0) {
            item = JSON.parse(JSON.stringify(this.individualAgreemenItems[this.individualAgreemenItems.length - 1]));
          }
          this.individualAgreemenItems.push({
            ...item,
          });
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        // case this.bycounrtyType:
        //   items = this.counrtyItems.filter((item) => !item.isSelected);
        //   this.counrtyItems = items;
        //   break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(formData: any) {
      this.IndividualAgreements = formData.IndividualAgreements;
      this.TimoCom = formData.TimoCom;
      this.LoadFox = formData.LoadFox;
      this.Trans = formData.Trans;
      this.Cargoclix = formData.Cargoclix;
      this.BulkExchange = formData.BulkExchange;
      this.Sonstige = formData.Sonstige;
      this.SonstigeText = formData.SonstigeText;
      this.Others = formData.Others;
      this.Others2 = formData.Others2;
      this.OthersText = formData.OthersText;
      this.OthersText2 = formData.OthersText2;
      this.AGB_449 = formData.AGB_449;
      this.ADSp = formData.ADSp;
      this.AGB_BSK = formData.AGB_BSK;
      this.AGBremovals = formData.AGBremovals;
      this.VBGL = formData.VBGL;
      this.AGB = formData.AGB;
      this.grossAnnual = formData.grossAnnual;
      this.GeneralFreight = formData.GeneralFreight;
      this.ChilledGoods = formData.ChilledGoods;
      this.FrozenFood = formData.FrozenFood;
      this.TemperatureControlled = formData.TemperatureControlled;
      this.over100k = formData.over100k;
      this.HighQuality = formData.HighQuality;
      this.over300k = formData.over300k;
      this.CEPServices = formData.CEPServices;
      this.Bulk = formData.Bulk;
      this.MovingGoods = formData.MovingGoods;
      this.HeavyLift = formData.HeavyLift;
      this.GoodsInTank = formData.GoodsInTank;
      this.MotorVehiclesOfAllKinds = formData.MotorVehiclesOfAllKinds;
      this.artMoneyValuablesDocuments = formData.artMoneyValuablesDocuments;
      this.animalsAndPlants = formData.animalsAndPlants;
      this.SumSpecialTypes = formData.SumSpecialTypes;
      this.SumSpecialTypesError = formData.SumSpecialTypesError;
      this.MotorVehicle = formData.MotorVehicle;
      this.Lohnfuhr = formData.Lohnfuhr;
      this.See = formData.See;
      this.Luft = formData.Luft;
      this.KnownSender = formData.KnownSender;
      this.RegulatedAgent = formData.RegulatedAgent;
      this.Bahn = formData.Bahn;
      this.Binnenschiff = formData.Binnenschiff;
      this.Multimodal = formData.Multimodal;
      this.UnusualLogisticalServices = formData.UnusualLogisticalServices;
      this.priceLabeling = formData.priceLabeling;
      this.assembly = formData.assembly;
      this.repairs = formData.repairs;
      this.textiles = formData.textiles;
      this.OrderProcessing = formData.OrderProcessing;
      this.goodsToBeDelivered = formData.goodsToBeDelivered;
      this.otherProcessingOfCustomsGoods = formData.otherProcessingOfCustomsGoods;
      this.customaryForForwardingAgents = formData.customaryForForwardingAgents;
      this.SonstigeUnusualLogisticalServices = formData.SonstigeUnusualLogisticalServices;
      this.feedBack = formData.feedBack;
      this.Dokumente = formData.Dokumente;
      this.Weltweit = formData.Weltweit;
      this.Deutschland = formData.Deutschland;
      this.Angrenzende = formData.Angrenzende;
      this.Europa = formData.Europa;
      this.Nordamerika = formData.Nordamerika;
      this.Mittelamerika = formData.Mittelamerika;
      this.Sudamerika = formData.Sudamerika;
      this.Afrika = formData.Afrika;
      this.China = formData.China;
      this.Japan = formData.Japan;
      this.Asien = formData.Asien;
      this.Arabische = formData.Arabische;
      this.Australien = formData.Australien;
      this.insuranceCoverage = formData.insuranceCoverage;
      this.T1Papers = formData.T1Papers;
      this.T2Papers = formData.T2Papers;
      this.freeCirculation = formData.freeCirculation;
      this.MarketRegulationGoods = formData.MarketRegulationGoods;
      this.subjectToExciseDuty = formData.subjectToExciseDuty;
      this.CustomsClearanceOutsideEU = formData.CustomsClearanceOutsideEU;
      this.CustomsClearanceOutsideEUText = formData.CustomsClearanceOutsideEUText;
      this.FiscalRepresentation = formData.FiscalRepresentation;
      this.SubmissionDeclarations = formData.SubmissionDeclarations;
      this.commitmentAccording = formData.commitmentAccording;
      this.ForwadingActivitesRemarks = formData.ForwadingActivitesRemarks;
      this.documentsIssuedWithAnObligation = formData.documentsIssuedWithAnObligation;
      this.TBL = formData.TBL;
      this.FBL = formData.FBL;
      this.FCT = formData.FCT;
      this.FCR = formData.FCR;
      this.AWB = formData.AWB;
      this.vehicleListTypes = formData.vehicleListTypes;
      this.individualAgreemenItems = formData.individualAgreemenItems;
      this.AGBinsuranceFile = formData.AGBinsuranceFile;
      this.HaftunginsuranceFile = formData.HaftunginsuranceFile;
      this.UnusualLogisticalServicesFile = formData.UnusualLogisticalServicesFile;
      this.TBLinsuranceFile = formData.TBLinsuranceFile;
      this.AWBinsuranceFile = formData.AWBinsuranceFile;
      this.DokumenteFile = formData.DokumenteFile;
      this.OthersFile = formData.OthersFile;
      this.spatialAreaValues = formData.spatialAreaValues ?? this.spatialAreaValues;
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('border-danger')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onSubmit() {
      if (!this.validations()) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = {
        IndividualAgreements: this.IndividualAgreements,
        TimoCom: this.TimoCom,
        LoadFox: this.LoadFox,
        Trans: this.Trans,
        Cargoclix: this.Cargoclix,
        BulkExchange: this.BulkExchange,
        Sonstige: this.Sonstige,
        SonstigeText: this.SonstigeText,
        Others: this.Others,
        Others2: this.Others2,
        OthersText: this.OthersText,
        OthersText2: this.OthersText2,
        AGB_449: this.AGB_449,
        ADSp: this.ADSp,
        AGB_BSK: this.AGB_BSK,
        AGBremovals: this.AGBremovals,
        VBGL: this.VBGL,
        AGB: this.AGB,
        grossAnnual: this.grossAnnual,
        GeneralFreight: this.GeneralFreight,
        ChilledGoods: this.ChilledGoods,
        FrozenFood: this.FrozenFood,
        TemperatureControlled: this.TemperatureControlled,
        over100k: this.over100k,
        HighQuality: this.HighQuality,
        over300k: this.over300k,
        CEPServices: this.CEPServices,
        Bulk: this.Bulk,
        MovingGoods: this.MovingGoods,
        HeavyLift: this.HeavyLift,
        GoodsInTank: this.GoodsInTank,
        MotorVehiclesOfAllKinds: this.MotorVehiclesOfAllKinds,
        artMoneyValuablesDocuments: this.artMoneyValuablesDocuments,
        animalsAndPlants: this.animalsAndPlants,
        SumSpecialTypes: this.SumSpecialTypes,
        SumSpecialTypesError: this.SumSpecialTypesError,
        MotorVehicle: this.MotorVehicle,
        Lohnfuhr: this.Lohnfuhr,
        See: this.See,
        Luft: this.Luft,
        KnownSender: this.KnownSender,
        RegulatedAgent: this.RegulatedAgent,
        Bahn: this.Bahn,
        Binnenschiff: this.Binnenschiff,
        Multimodal: this.Multimodal,
        UnusualLogisticalServices: this.UnusualLogisticalServices,
        priceLabeling: this.priceLabeling,
        assembly: this.assembly,
        repairs: this.repairs,
        textiles: this.textiles,
        OrderProcessing: this.OrderProcessing,
        goodsToBeDelivered: this.goodsToBeDelivered,
        otherProcessingOfCustomsGoods: this.otherProcessingOfCustomsGoods,
        customaryForForwardingAgents: this.customaryForForwardingAgents,
        SonstigeUnusualLogisticalServices: this.SonstigeUnusualLogisticalServices,
        feedBack: this.feedBack,
        Dokumente: this.Dokumente,
        Weltweit: this.Weltweit,
        Deutschland: this.Deutschland,
        Angrenzende: this.Angrenzende,
        Europa: this.Europa,
        Nordamerika: this.Nordamerika,
        Mittelamerika: this.Mittelamerika,
        Sudamerika: this.Sudamerika,
        Afrika: this.Afrika,
        China: this.China,
        Japan: this.Japan,
        Asien: this.Asien,
        Arabische: this.Arabische,
        Australien: this.Australien,
        insuranceCoverage: this.insuranceCoverage,
        T1Papers: this.T1Papers,
        T2Papers: this.T2Papers,
        freeCirculation: this.freeCirculation,
        MarketRegulationGoods: this.MarketRegulationGoods,
        subjectToExciseDuty: this.subjectToExciseDuty,
        CustomsClearanceOutsideEU: this.CustomsClearanceOutsideEU,
        CustomsClearanceOutsideEUText: this.CustomsClearanceOutsideEUText,
        FiscalRepresentation: this.FiscalRepresentation,
        SubmissionDeclarations: this.SubmissionDeclarations,
        commitmentAccording: this.commitmentAccording,
        ForwadingActivitesRemarks: this.ForwadingActivitesRemarks,
        documentsIssuedWithAnObligation: this.documentsIssuedWithAnObligation,
        TBL: this.TBL,
        FBL: this.FBL,
        FCT: this.FCT,
        FCR: this.FCR,
        AWB: this.AWB,
        vehicleListTypes: this.vehicleListTypes,
        individualAgreemenItems: this.individualAgreemenItems,
        AGBinsuranceFile: this.AGBinsuranceFile,
        HaftunginsuranceFile: this.HaftunginsuranceFile,
        UnusualLogisticalServicesFile: this.UnusualLogisticalServicesFile,
        TBLinsuranceFile: this.TBLinsuranceFile,
        AWBinsuranceFile: this.AWBinsuranceFile,
        DokumenteFile: this.DokumenteFile,
        OthersFile: this.OthersFile,
        spatialAreaValues: this.spatialAreaValues,
      };
      this.$emit('forwarding-activities-data-changed', data);
      this.$router.push({
        name: this.nextPath,
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goToBackUrl() {
      this.$router.push({
        name: this.backPath,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    validations() {
      if (this.isAGBError && this.AGB) {
        this.AGBError = true;
        return false;
      }
      if (this.isGrossAnnualError) {
        this.grossAnnualError = true;
        return false;
      }
      if (this.isSumSpecialTypesError) {
        this.SumSpecialTypesError = true;
        return false;
      }
      if (this.isOrganizationError) {
        this.OrganizationError = true;
        return false;
      }
      if (this.isUnusualLogisticalServicesError && this.UnusualLogisticalServices) {
        this.UnusualLogisticalServicesError = true;
        return false;
      }
      if (this.isdocumentsIssuedWithAnObligationError && this.documentsIssuedWithAnObligation) {
        this.documentsIssuedWithAnObligationError = true;
        return false;
      }
      if (this.isinsuranceCoverageError && this.insuranceCoverage) {
        this.insuranceCoverageError = true;
        return false;
      }
      if (this.isSumSpatialAreaValuesError) {
        this.sumSpatialAreaValuesError = true;
        return false;
      }
      return true;
    },
  },
  computed: {
    isAGBError(): boolean {
      return !this.AGB_449 && !this.ADSp && !this.AGB_BSK && !this.AGBremovals && !this.VBGL && !this.Others;
    },
    isGrossAnnualError(): boolean {
      return this.grossAnnual <= 0;
    },
    isSumSpecialTypesError(): boolean {
      return this.SumSpecialTypes !== 100;
    },
    isSumSpatialAreaValuesError(): boolean {
      return this.sumSpatialAreaValues !== 100;
    },
    sumSpatialAreaValues(): number {
      return Object.values(this.spatialAreaValues)
        .map(Number)
        .reduce((total, value) => total + value, 0);
    },
    isOrganizationError(): boolean {
      return !(this.MotorVehicle || this.Lohnfuhr || this.See || this.Luft || this.KnownSender || this.RegulatedAgent || this.Bahn || this.Binnenschiff || this.Multimodal);
    },
    isUnusualLogisticalServicesError(): boolean {
      return !(this.priceLabeling || this.assembly || this.repairs || this.textiles || this.OrderProcessing || this.goodsToBeDelivered || this.otherProcessingOfCustomsGoods || this.customaryForForwardingAgents);
    },
    isdocumentsIssuedWithAnObligationError(): boolean {
      return !(this.TBL || this.FBL || this.FCT || this.FCR || this.AWB || this.Dokumente);
    },
    isinsuranceCoverageError(): boolean {
      return !(this.T1Papers === '' || this.T2Papers === '' || this.freeCirculation === '' || this.MarketRegulationGoods || this.subjectToExciseDuty || this.CustomsClearanceOutsideEU || this.FiscalRepresentation || this.SubmissionDeclarations);
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    nextPath(): string {
      let result = '';
      if (this.progressBar.watchman) {
        result = 'watchman';
      } else {
        result = 'logistics-insurance-price-card';
      }
      return result;
    },
    backPath(): string {
      let result = 'logistics-insurance';
      if (this.progressBar.roadCarrier) {
        result = 'road-haulier';
      }
      return result;
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.formData.forwardingActivitiesFormData) {
      this.setValues(this.formData.forwardingActivitiesFormData);
    }
  },
});
